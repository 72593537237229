
.container {
    width: 600px;    
    height: 90vh;
}

.child {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
