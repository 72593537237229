.welcome_user {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -100%);

    width:400px;
    height:200px;
}
.img {
    width:200px;
    height:200px;
}
.welcome_button {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -51%);


    width:200px;
    height:200px;
    border-radius: 100px;
    font-size: 30px;
}