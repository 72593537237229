@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
/* base styles */
body {
  font-family: Quicksand, sans-serif;
  color:seashell;
  margin: 0;
  font-size: 1.1em;
  background: rgb(63, 60, 101);
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.center-text {
  text-align: center;
}

.wrapper-flex {
  display: flex;
  justify-content: space-between;
}
.btn {
  background: none;
  border: 2px solid #ffffff;
  padding: 6px 12px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: #874649;
  color: #fff;
}
.Header_title__3Y4b5{
    font-size: 60px;
    margin: 0;
    padding: 0;
}
.Login_login-form__KBqhQ {
    max-width: 360px;
    margin: 0px auto;
    padding: 0px;
}
.Login_login-form__KBqhQ label {
    display: block;
    margin: 0 auto 0px auto;
}
.Login_login-form__KBqhQ span {
    display: block;
    margin-bottom: 6px;
}
.Login_login-buttons__1CbbK {
    margin: 20px;
}
.Login_login-form__KBqhQ input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
}
.Login_login-link__2eTKb {
    color:azure
}
.Login_login-link__2eTKb:visited {
    color:azure
}
.Signup_signup-form__2CHKs {
    max-width: 360px;
    margin: 0px auto;
    padding: 0px;
}
.Signup_signup-form__2CHKs label {
    display: block;
    margin: 0 auto 0px auto;
}
.Signup_signup-form__2CHKs span {
    display: block;
    margin-bottom: 6px;
}
.Signup_signup-buttons__2bVpm {
    margin: 20px;
}
.Signup_signup-form__2CHKs input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
}
.Signup_signup-link__AMxqz {
    color:azure
}
.Signup_signup-link__AMxqz:visited {
    color:azure
}
.Welcome_welcome_user__147ia {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);

    width:400px;
    height:200px;
}
.Welcome_img__21f66 {
    width:200px;
    height:200px;
}
.Welcome_welcome_button__3XB36 {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -51%);
            transform: translate(-50%, -51%);


    width:200px;
    height:200px;
    border-radius: 100px;
    font-size: 30px;
}

.Today_container__1jkGB {
    width: 600px;    
    height: 90vh;
}

.Today_child__2qG9o {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.Day_day__aKVmJ {
    width: 100%;    
    height: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 24fr;
    grid-template-areas:
    "header"
    "body";

    justify-items: center;

    padding: 0;
    margin: auto;

    border-radius: 5px;
    box-shadow: 0px 5px 5px 5px black ;
}

.Day_day_header__3JnXE { 
    grid-row: header;
    margin: 0px auto;
    padding: 5px 0px;
    height: 100%;
    width: 100%;
    
    background-color: #874649;
    border-radius: 5px;
}
.Day_day_date__3IaQ5 { 
    padding: 0;
    margin: 0;
    text-align: center;
    color: aliceblue;
}
.Day_day_time__n5A0l { 
    padding: 0;
    margin: 50;
    text-align: center;
    color: aliceblue;
}

.Day_day_body_shape__2kevM { 
    grid-row: body;
    margin: 0 auto;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 8fr;
    grid-template-rows: auto;
    
}
.Day_day_body_style__37tDF {
    background-color: aliceblue;
    border-radius: 5px;
}

.Day_day_hour__1xCht {
    border-bottom: dashed 2px grey;
    border-right: solid 2px grey;

    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    grid-column: 1;
    /* display: grid; */
    /* grid-template-columns: 1fr 8fr; */
}

.Day_day_hour_label__3iFq4 {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;

    font-size: 2em;
}
/*
.day_day { 
    position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
}
.day_hour {
    
    list-style-type:none;
    width: 100%;
}
.day_hour td {
    text-align: left;
    padding: 0px 0px 0px 5px;
    margin: 0;
} */

.Day_past__ttb8N {
    /* border: dashed 2px grey; */
    background-color: rgb(193, 193, 193);
    color: white;
}
.Day_present__21KMi {
    background-color: rgb(255, 200, 0);
    color: rgb(0, 0, 0);
    font-weight: bold;
    /* font-size: 1.0em; */
    border-bottom: solid 4px black;
    border-top: solid 2px black;
    /* box-shadow: 0px 2px 1px 1px black ; */
}
.Day_future__2UzT4 {
    background-color: none;
    color: rgb(0, 0, 0);
    /* border-bottom: dashed 2px rgb(193, 193, 193); */
}


.Planner_planner_tasks__Pldc_ { 
    grid-row: body;
    margin: 0 auto;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 8fr;
    grid-template-rows: auto;
    
    /* background-color: aliceblue; */
    border-radius: 5px;
}


.Planner_task__1Ltv3 {
    grid-column: 2;
    grid-row: 2;

    width: 100%;

    background-color: brown;
    box-shadow: 0px 2px 2px 2px rgb(146, 146, 146) ;
    border-radius: 10px;
}
