.login-form {
    max-width: 360px;
    margin: 0px auto;
    padding: 0px;
}
.login-form label {
    display: block;
    margin: 0 auto 0px auto;
}
.login-form span {
    display: block;
    margin-bottom: 6px;
}
.login-buttons {
    margin: 20px;
}
.login-form input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
}
.login-link {
    color:azure
}
.login-link:visited {
    color:azure
}