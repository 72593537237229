@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

/* base styles */
body {
  font-family: Quicksand, sans-serif;
  color:seashell;
  margin: 0;
  font-size: 1.1em;
  background: rgb(63, 60, 101);
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.center-text {
  text-align: center;
}

.wrapper-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.btn {
  background: none;
  border: 2px solid #ffffff;
  padding: 6px 12px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: #874649;
  color: #fff;
}