.day {
    width: 100%;    
    height: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 24fr;
    grid-template-areas:
    "header"
    "body";

    justify-items: center;

    padding: 0;
    margin: auto;

    border-radius: 5px;
    box-shadow: 0px 5px 5px 5px black ;
}

.day_header { 
    grid-row: header;
    margin: 0px auto;
    padding: 5px 0px;
    height: 100%;
    width: 100%;
    
    background-color: #874649;
    border-radius: 5px;
}
.day_date { 
    padding: 0;
    margin: 0;
    text-align: center;
    color: aliceblue;
}
.day_time { 
    padding: 0;
    margin: 50;
    text-align: center;
    color: aliceblue;
}

.day_body_shape { 
    grid-row: body;
    margin: 0 auto;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 8fr;
    grid-template-rows: auto;
    
}
.day_body_style {
    background-color: aliceblue;
    border-radius: 5px;
}

.day_hour {
    border-bottom: dashed 2px grey;
    border-right: solid 2px grey;

    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    grid-column: 1;
    /* display: grid; */
    /* grid-template-columns: 1fr 8fr; */
}

.day_hour_label {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;

    font-size: 2em;
}
/*
.day_day { 
    position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
}
.day_hour {
    
    list-style-type:none;
    width: 100%;
}
.day_hour td {
    text-align: left;
    padding: 0px 0px 0px 5px;
    margin: 0;
} */

.past {
    /* border: dashed 2px grey; */
    background-color: rgb(193, 193, 193);
    color: white;
}
.present {
    background-color: rgb(255, 200, 0);
    color: rgb(0, 0, 0);
    font-weight: bold;
    /* font-size: 1.0em; */
    border-bottom: solid 4px black;
    border-top: solid 2px black;
    /* box-shadow: 0px 2px 1px 1px black ; */
}
.future {
    background-color: none;
    color: rgb(0, 0, 0);
    /* border-bottom: dashed 2px rgb(193, 193, 193); */
}
