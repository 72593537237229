
.planner_tasks { 
    grid-row: body;
    margin: 0 auto;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 8fr;
    grid-template-rows: auto;
    
    /* background-color: aliceblue; */
    border-radius: 5px;
}


.task {
    grid-column: 2;
    grid-row: 2;

    width: 100%;

    background-color: brown;
    box-shadow: 0px 2px 2px 2px rgb(146, 146, 146) ;
    border-radius: 10px;
}